import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import cn from 'classnames'
import { StyleSheet, css } from 'aphrodite'
import Helmet from 'react-helmet'
import Img from 'react-image'
import Fade from 'react-reveal/Fade'
import AlternativeLayout from '../layouts/AltertineLayout'
import Classes from '../../styles/classes'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})

const TopCorner = ({ i }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
    class="svg-separator"
  >
    <polygon fill="#F6F9FF" points="0,100 100,0 100,100" />
  </svg>
)
const BottomCorner = ({ i }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
    class="svg-separator"
  >
    <polygon fill="#FFFFFF" points="0,100 100,0 100,100" />
  </svg>
)

const RowContainer = ({ i, size, children }) => {
  return (
    <div
      style={{
        position: 'relative',
        backgroundColor: i % 2 !== 0 ? '#F6F9FF' : '#FFF'
      }}
    >
      <div className="container">
        <div
          className={cn(
            'row',
            'bloc_contenu',
            'aligned-row',
            i % 2 === 0 && 'increase_padding'
          )}
        >
          {i % 2 === 0 && i + 1 !== size && <TopCorner />}
          {children}
          {i % 2 !== 0 && <BottomCorner />}
        </div>
      </div>
    </div>
  )
}

export default ({ data }) => {
  const produit = data.produit
  const service = data.produit.service
  console.log('produit', produit)
  const breadcrumbLinks = [
    <Link
      to={service.permalink}
      title={service.titre}
      className={css(Classes.link)}
    >
      {service.titre}
    </Link>,
    <Link
      to={produit.permalink}
      title={produit.titre}
      className={css(Classes.link)}
    >
      {produit.titre}
    </Link>
  ]

  let Content = <ReactMarkdown source={produit.contenu.contenu} />

  if (produit.blocs && produit.blocs.length > 0) {
    const blocsSize = produit.blocs.length
    Content = produit.blocs.map((bloc, i) => {
      if (bloc.imageGauche && bloc.imageDroite) {
        return (
          <RowContainer key={bloc.id} i={i} size={blocsSize}>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <Fade up>
                <div className="image_conainer_produit">
                  <Img src={bloc.imageGauche} decode={false} />
                </div>
              </Fade>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <div className="flex-container">
                <div>
                  <ReactMarkdown source={bloc.contenu.contenu} />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
              <Fade up>
                <div className="image_conainer_produit">
                  <Img src={bloc.imageDroite} decode={false} />
                </div>
              </Fade>
            </div>
          </RowContainer>
        )
      } else if (bloc.imageGauche) {
        return (
          <RowContainer key={bloc.id} i={i} size={blocsSize}>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <Fade up>
                <div className="image_conainer_produit">
                  <Img src={bloc.imageGauche} decode={false} />
                </div>
              </Fade>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="flex-container">
                <div>
                  <ReactMarkdown source={bloc.contenu.contenu} />
                </div>
              </div>
            </div>
          </RowContainer>
        )
      } else if (bloc.imageDroite) {
        return (
          <RowContainer key={bloc.id} i={i} size={blocsSize}>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="flex-container">
                <div>
                  <ReactMarkdown source={bloc.contenu.contenu} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <Fade up>
                <div className="image_conainer_produit">
                  <Img src={bloc.imageDroite} decode={false} />
                </div>
              </Fade>
            </div>
          </RowContainer>
        )
      } else {
        return (
          <RowContainer key={bloc.id} i={i} size={blocsSize}>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ReactMarkdown source={bloc.contenu.contenu} />
            </div>
          </RowContainer>
        )
      }
    })
  }

  return (
    <AlternativeLayout
      title={produit.titre}
      subtitle={produit.description.description}
      image={produit.bandeau.resolutions.src}
      contact
      breadcrumbLinks={breadcrumbLinks}
    >
      <Helmet
        title={produit.metaTitle}
        meta={[
          {
            name: 'description',
            content: produit.metaDescription
          }
        ]}
      />
      <section className="section-padding">
        <div className="description-produit">{Content}</div>
      </section>
    </AlternativeLayout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    produit: contentfulProduits(id: { eq: $id }) {
      id
      titre
      permalink
      metaTitle
      metaDescription
      description {
        description
      }
      contenu {
        contenu
      }
      bandeau {
        resolutions(width: 1600) {
          width
          height
          src
          srcSet
        }
      }
      service {
        titre
        permalink
      }
      blocs {
        id
        imageGauche
        imageDroite
        contenu {
          contenu
        }
      }
    }
  }
`
